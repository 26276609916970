'use client'

import { useEffect } from 'react'
import { signIn } from 'next-auth/react'

interface LoginProps {
  callbackUrl: string
  provider: string
}

export default function Login({ callbackUrl, provider }: LoginProps) {
  useEffect(() => {
    void signIn(provider, { callbackUrl })
  }, [callbackUrl, provider])

  return null
}
